import route from "ziggy-js";
import {Ziggy} from "./ziggy";

import {checkRouteAndRedirect} from "./modules/redirector";

function init() {

    checkRouteAndRedirect();

    let section;

    section = route(undefined, undefined, undefined, Ziggy);

    if (section.current('landing')) {

        (async () => {
            (await import('./lp/lp')).init();
        })();
    } else {

        (async () => {
            (await import('./app/app')).init();
        })();
    }
}
init();
